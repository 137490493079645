<template>
  <div>
    <div
      class="modal fade"
      id="modalAdminAddPac"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAdminAddPacLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog"
        role="document"
      >
        <div class="modal-content">
          <div class="d-flex justify-content-between p-3">
            <h5
              class="modal-title"
              id="modalAdminAddPacLabel"
            >Thêm mới pac</h5>
            <button
              type="button"
              class="close bg-white border-0"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                class="robo-18-500"
              >&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mt-2">
              <div class="col-sm-12">
                <div style="padding: 15px 15px;">
                  <div class="row">
                    <div class="form-group mb-3">
                      <label class="robo-14-400 txt-grey-600 mb-2">Tên bệnh nhân</label>
                      <v-select
                        v-if="persons && persons.data"
                        class="txt-black bg-white border radius-10 border-2"
                        :options="persons.data"
                        label="name"
                        placeholder="Chọn bệnh nhân"
                        :filterable="false"
                        @open="onOpen"
                        @close="onClose"
                        v-model="selected_person"
                        transition=""
                        @search="searchPerson"
                        @input="getDiseases"
                      >
                        <div slot="no-options">Not found!</div>
                        <template v-slot:option="item">
                          <span class="font-weight-bold">{{item.name}} - {{item.phone}}</span>
                        </template>
                        <template #list-footer>
                          <li
                            ref="load"
                            v-show="hasNextPage"
                          ></li>
                        </template>
                      </v-select>
                    </div>
                    <div class="mb-3">
                      <label class="robo-14-400 txt-grey-600 required mb-2">Hồ Sơ Sức Khỏe</label>
                      <v-select
                        class="txt-black bg-white border radius-10 border-2"
                        :options="diseases.data"
                        label="diseases_name"
                        placeholder="Hồ Sơ Sức Khỏe"
                        :filterable="false"
                        @open="onOpenDisease"
                        @close="onCloseDisease"
                        v-model="slt_disease"
                        transition=""
                        @search="searchDisease"
                      >
                        <div slot="no-options">Chưa có hồ sơ nào!</div>
                        <template #list-footer>
                          <li
                            ref="load"
                            v-show="diseaseHasNextPage"
                          ></li>
                          <div
                            class="d-flex align-items-center justify-content-center"
                            v-if="loading_disease"
                          >
                            <div
                              class="spinner-border mt-5"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        </template>
                      </v-select>
                    </div>
                    <div
                      class="mb-3"
                      v-if="slt_disease"
                    >
                      <div class="row">
                        <div class="col-12">
                          <p class="robo-16-400 txt-black">{{getPersonInfoString(slt_disease.person)}}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <p class="robo-16-400 txt-black">Chẩn đoán: </p>
                        </div>
                        <div class="col-8">
                          <p class="robo-16-400 txt-black">{{slt_disease.diseases_name}}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <p class="robo-16-400 txt-black">Tình trạng hiện tại:</p>
                        </div>
                        <div class="col-8">
                          <p
                            class="robo-16-400 txt-black"
                            style="white-space: pre-wrap;"
                          >{{slt_disease.diseases_current_cure}}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <p class="robo-16-400 txt-black">Điều trị:</p>
                        </div>
                        <div class="col-8">
                          <p
                            class="robo-16-400 txt-black"
                            style="white-space: pre-wrap;"
                          >{{slt_disease.diseases_previous_cure}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mb-3">
            <button
              :disabled="loading"
              class="btn radius-0 bg-pri bd-pri text-white border-0 px-5"
              type="button"
              @click="onSubmit"
            >Thêm
              <span
                class="spinner-border spinner-border-sm mr-2"
                role="status"
                v-if="loading"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import vSelect from 'vue-select'
export default {
  name: 'ModalAdminAddPac',
  components: { vSelect },
  data () {
    return {
      observer: null,
      observerDisease: null,
      selected_person: null,
      slt_disease: null,
      diseases: [],
      persons: [],
      loading: false,
      search_person: '',
      current_page: 1,
      loading_disease: false
    }
  },
  mounted () {
    let self = this
    self.observer = new IntersectionObserver(self.infiniteScroll)
    self.observerDisease = new IntersectionObserver(self.infiniteScrollDisease)
    self.getDiseases(20)
    self.getPersons(20)
    window.$(document).ready(function () {
      window.$('#modalAdminAddPac').on('shown.bs.modal', function (e) {
      })
      window.$('#modalAdminAddPac').on('hide.bs.modal', function (e) {
        self.selected_person = null
        self.slt_disease = null
      })
    })
  },
  methods: {
    async getPersons (num) {
      let self = this
      try {
        self.current_page = parseInt(self.$route.query.page) || 1
        self.loading = !num
        let params = {
          limit: num || 50,
          sort: 'name',
          sort_by: 'asc',
          search: self.search_person
        }
        await self.$rf.getRequest('AdminRequest').getPersons(params).then(res => {
          self.persons = res.data
        })
        return true
      } catch (e) {
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    hasNextPage () {
      let self = this
      if (self.persons.total > self.persons.count) {
        return true
      }
      return false
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    searchPerson (search) {
      let self = this
      if (search) {
        self.onSearhPerson(search)
      } else {
        self.onSearchPersonDelete()
      }
    },
    onSearhPerson (search) {
      let self = this
      self.search_person = search
      self.getPersons()
    },
    onSearchPersonDelete () {
      let self = this
      self.search_person = ''
      self.getPersons()
    },
    async infiniteScroll ([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        var params = {
          limit: this.persons.count + 20
        }
        await this.$nextTick()
        ul.scrollTop = scrollTop
        this.$emit('onRefresh', params.limit)
      }
    },
    diseaseHasNextPage () {
      let self = this
      if (self.diseases.total > self.diseases.count) {
        return true
      }
      return false
    },
    async onOpenDisease () {
      if (this.diseaseHasNextPage) {
        await this.$nextTick()
        this.observerDisease.observe(this.$refs.load)
      }
    },
    onCloseDisease () {
      this.observerDisease.disconnect()
    },
    searchDisease (search) {
      let self = this
      self.getDiseases(20, search)
    },
    async infiniteScrollDisease ([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        var params = {
          limit: this.diseases.count + 20
        }
        await this.$nextTick()
        ul.scrollTop = scrollTop
        this.$emit('onRefresh', params.limit)
      }
    },
    async getDiseases (limit, search) {
      let self = this
      self.loading_disease = true
      let params = {
        limit,
        sort: 'updated_at',
        sort_by: 'desc'
      }
      if (self.selected_person) {
        params.person_id = self.selected_person.id
      }
      if (search) {
        params.search = search
      }
      await self.$rf.getRequest('AdminRequest').getPersonsDiseases(params).then(res => {
        self.diseases = res.data
      }).finally(() => {
        self.loading_disease = false
      })
    },
    onSubmit () {
      if (!this.slt_disease) {
        alert('Vui lòng chọn HSSK')
        return
      }
      this.$emit('addARecord', this.slt_disease)
    },
    getPersonInfoString (p) {
      return p.name + ' (' + this.getGender(p.gender) + ' - ' + this.getAges(p.birthday) + ' - ' + p.address + ')'
    },
    getGender (gender = 1) {
      return gender === 1 ? 'Nam' : gender === 2 ? 'Nữ' : gender === 3 ? 'Khác' : ''
    },
    getAges (date) {
      let bd = window.moment(date)
      let age = window.moment().diff(bd, 'years')
      return age + ' tuổi'
    }
  }
}
</script>
<style lang="css" scoped>
.input-date {
  height: 40px !important;
  font-size: 16px !important;
  color: black !important;
  padding-left: 5px !important;
}
</style>